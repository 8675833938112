import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
var AskUserInfoComponent = /** @class */ (function () {
    function AskUserInfoComponent(fb, commonService) {
        this.fb = fb;
        this.commonService = commonService;
        this.errMsg = "";
        this.apiCall = false;
    }
    AskUserInfoComponent.prototype.ngOnInit = function () {
        this.initForm();
    };
    AskUserInfoComponent.prototype.ngAfterViewInit = function () {
        this.subscribedData();
    };
    AskUserInfoComponent.prototype.initForm = function () {
        this.userDetailsForm = this.fb.group({
            fullName: ["", [Validators.required, Validators.pattern(/^[a-zA-Z ]{2,35}$/)]],
            email: ["", [Validators.required, Validators.email]],
            mobileNo: ["", [Validators.required, Validators.pattern(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/), Validators.maxLength(10)]],
        });
    };
    Object.defineProperty(AskUserInfoComponent.prototype, "f", {
        get: function () { return this.userDetailsForm.controls; },
        enumerable: true,
        configurable: true
    });
    AskUserInfoComponent.prototype.subscribedData = function () {
        var _this = this;
        this.commonService.getCommonSubject()
            .subscribe(function (res) {
            switch (res.action) {
                case 'showASKUserInfo': {
                    var _a = res.data, storeRiskProfile = _a.storeRiskProfile, riskGameSteps = _a.riskGameSteps;
                    storeRiskProfile = storeRiskProfile && storeRiskProfile.map(function (s) { return s.answer; });
                    _this.userRiskGame = { storeRiskProfile: storeRiskProfile, riskGameSteps: riskGameSteps };
                    _this.showUserModel();
                    break;
                }
                case 'closeASKUserInfo': {
                    _this.hideUserModel();
                    break;
                }
            }
        });
    };
    AskUserInfoComponent.prototype.showUserModel = function () {
        UIkit.modal('#getUserInfo').show();
    };
    AskUserInfoComponent.prototype.hideUserModel = function () {
        UIkit.modal('#getUserInfo').hide();
    };
    AskUserInfoComponent.prototype.subscribeUser = function () {
        var _this = this;
        if (this.userDetailsForm.valid) {
            var getUserInfo_1 = this.userDetailsForm.getRawValue();
            getUserInfo_1.userAssest = this.addAssetsUsers();
            this.commonService.subscribeUser(getUserInfo_1).subscribe(function (res) {
                _this.commonService.sendCommonSubject('closeASKUserInfo', { getUserInfo: getUserInfo_1 });
                _this.userDetailsForm.reset();
                // this.commonService.setLocalStorage('givenInfo', JSON.stringify(getUserInfo));
            });
        }
    };
    AskUserInfoComponent.prototype.addAssetsUsers = function () {
        var _a, e_1, _b;
        var userAssest = this.commonService.getDataFromLocalStorage("userAssest");
        if (userAssest) {
            var tempAssest = (_a = {}, _a[userAssest] = {}, _a);
            try {
                for (var _c = tslib_1.__values(Object.entries(this.userRiskGame)), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var _e = tslib_1.__read(_d.value, 2), key = _e[0], value = _e[1];
                    tempAssest[userAssest]["" + key] = value;
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_b = _c.return)) _b.call(_c);
                }
                finally { if (e_1) throw e_1.error; }
            }
            return tempAssest;
        }
        return "";
    };
    return AskUserInfoComponent;
}());
export { AskUserInfoComponent };
