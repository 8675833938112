import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../common/common.service";
var BrokersService = /** @class */ (function () {
    function BrokersService(http, commonService) {
        this.http = http;
        this.commonService = commonService;
    }
    BrokersService.prototype.getTradesDetails = function (reqBody) {
        reqBody.userId = this.commonService.getUserId();
        return this.http.post("users/basketOrderWithEditGoalId", reqBody);
    };
    //Alice blue API
    BrokersService.prototype.linkAliceBlue = function (token) {
        return this.http.post("alice-blues/alice-blue-link", token);
    };
    BrokersService.prototype.executeAliceBlueTrades = function (reqBody) {
        return this.http.post("alice-blues/alice-blue-order", reqBody);
    };
    //End Alice blue API
    BrokersService.prototype.linkZerodha = function (reqBody) {
        return this.http.post("broker-links/zerodha", reqBody);
    };
    BrokersService.prototype.updateBrokerAccessToken = function (reqBody) {
        var userId = this.commonService.getUserId();
        return this.http.put("users/" + userId + "/updatebrokerinfo", reqBody);
    };
    //5paisa blue API
    BrokersService.prototype.link5Paisa = function (token) {
        return this.http.post("fivePaisas/5paisa-link", token);
    };
    BrokersService.prototype.execute5PaisaTrades = function (reqBody) {
        return this.http.post("fivePaisas/5paisa-order", reqBody);
    };
    //End 5paisa API
    BrokersService.prototype.linkKotak = function (reqBody) {
        return this.http.post("kotaks/link-broker", reqBody);
    };
    BrokersService.prototype.executeKotakTrades = function (reqBody) {
        return this.http.post("kotaks/place-order", reqBody);
    };
    BrokersService.prototype.getEdelweissToken = function () {
        return this.http.get("edels/brokerToken");
    };
    BrokersService.prototype.linkEdelweiss = function (body) {
        return this.http.post("edels/link-broker", body);
    };
    BrokersService.prototype.executeEdelweissTrades = function (reqBody) {
        return this.http.post("edels/place-order", reqBody);
    };
    BrokersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BrokersService_Factory() { return new BrokersService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CommonService)); }, token: BrokersService, providedIn: "root" });
    return BrokersService;
}());
export { BrokersService };
