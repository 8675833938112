import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
var subject = new Subject();
var MfitService = /** @class */ (function () {
    function MfitService(http, router, activatedRoute) {
        this.http = http;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.mfitSubject = subject;
    }
    MfitService.prototype.getBondsSubject = function () {
        return this.mfitSubject.asObservable();
    };
    MfitService.prototype.senBondsSubject = function (action, data) {
        this.mfitSubject.next({ action: action, data: data });
    };
    MfitService.prototype.getPresignedPost = function (reqBody) {
        return this.http.post("mfPortfolios/generate-url", reqBody);
    };
    MfitService.prototype.uploadToS3 = function (url, formData) {
        return this.http.put(url, formData);
    };
    MfitService.prototype.extractInfo = function (reqBody) {
        return this.http.post("mfPortfolios/extract-info", reqBody);
    };
    MfitService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MfitService_Factory() { return new MfitService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i2.ActivatedRoute)); }, token: MfitService, providedIn: "root" });
    return MfitService;
}());
export { MfitService };
