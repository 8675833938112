import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../common/common.service";
var IciciService = /** @class */ (function () {
    function IciciService(http, commonService) {
        this.http = http;
        this.commonService = commonService;
    }
    IciciService.prototype.getICICIChecksum = function () {
        return this.http.get('icicis/getChecksum/');
    };
    IciciService.prototype.updateBroker = function (reqBody) {
        var userId = this.commonService.getUserId();
        return this.http.put("users/" + userId + "/updatebrokerinfo", reqBody);
    };
    IciciService.prototype.getTradesDetails = function (reqBody) {
        reqBody.userId = this.commonService.getUserId();
        return this.http.post("users/basketOrderWithEditGoalId", reqBody);
    };
    IciciService.prototype.getMarginValue = function (iciciUserId) {
        return this.http.get("icicis/getISECMargin/" + iciciUserId);
    };
    IciciService.prototype.executeTrades = function (orderInfo) {
        return this.http.post("icicis/executeTrades", orderInfo);
    };
    IciciService.prototype.getOrderStatus = function () {
        return this.http.get("icicis/getOrderUpdate");
    };
    IciciService.prototype.getPortfolio = function () {
        return this.http.get("icicis/getPortfolio");
    };
    IciciService.prototype.allocateFunds = function () {
        return this.http.get("icicis/allocateFunds");
    };
    IciciService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IciciService_Factory() { return new IciciService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CommonService)); }, token: IciciService, providedIn: "root" });
    return IciciService;
}());
export { IciciService };
