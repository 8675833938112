import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "../common/common.service";
var subject = new Subject();
var DashboardService = /** @class */ (function () {
    function DashboardService(http, router, activatedRoute, commonService) {
        this.http = http;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.commonService = commonService;
        this.dashboardSubject = subject;
    }
    DashboardService.prototype.getDashboardSubject = function () {
        return this.dashboardSubject.asObservable();
    };
    DashboardService.prototype.sendDashboardSubject = function (action, data) {
        this.dashboardSubject.next({ action: action, data: data });
    };
    DashboardService.prototype.validatePin = function (postBody) {
        return this.http.post("users/validatePin", postBody);
    };
    DashboardService.prototype.logout = function (postBody) {
        return this.http.post("users/logout", postBody);
    };
    DashboardService.prototype.getUserDetails = function () {
        return this.http.get("users/currentUser");
    };
    DashboardService.prototype.getSavedUserDetails = function () {
        var userInfo = localStorage.getItem('userInfo');
        userInfo = userInfo ? JSON.parse(userInfo) : "";
        return userInfo;
    };
    DashboardService.prototype.getUserId = function () {
        return this.commonService.getUserId();
    };
    DashboardService.prototype.getPortfolio = function () {
        return this.http.get("users/" + this.getUserId() + "/portfolio");
    };
    DashboardService.prototype.getGoals = function () {
        return this.http.get("users/" + this.getUserId() + "/goals");
    };
    DashboardService.prototype.getMoneyTransferDay = function () {
        return this.http.get("users/" + this.getUserId() + "/bankDetails/moneyTransferDay");
    };
    DashboardService.prototype.getNextTradingDay = function () {
    };
    DashboardService.prototype.getCompletePortfolio = function () {
        return this.http.get("users/getCompletePortfolio");
    };
    DashboardService.prototype.getGoalDetail = function (goalId) {
        return this.http.get("goals/" + goalId);
    };
    DashboardService.prototype.sendOTP = function (reqBody) {
        return this.http.put("users/" + this.getUserId() + "/sendOtpForPhone", reqBody);
    };
    DashboardService.prototype.validateOTP = function (reqBody) {
        return this.http.post("users/" + this.getUserId() + "/verifyOtpForPhone", reqBody);
    };
    DashboardService.prototype.updateUserInfo = function (reqBody) {
        reqBody.id = this.getUserId();
        return this.http.put("users/" + reqBody.id, reqBody);
    };
    DashboardService.prototype.savePANInfo = function (reqBody) {
        return this.http.put("users/" + this.getUserId() + "/userKyc", reqBody);
    };
    DashboardService.prototype.putRiskLevel = function (reqBody) {
        return this.http.put("users/" + this.getUserId() + "/riskLevel", reqBody);
    };
    DashboardService.prototype.storeRiskProfile = function (reqBody) {
        return this.http.post("riskProfiles/" + this.getUserId() + "/storeRiskProfile", reqBody);
    };
    DashboardService.prototype.getMonthlyAmount = function (reqBody) {
        var riskLevel = this.getSavedUserDetails();
        riskLevel = riskLevel && riskLevel.risk.riskLevel ? riskLevel.risk.riskLevel : 5;
        return this.http.post("risks/" + riskLevel + "/goalEstimate", reqBody);
    };
    DashboardService.prototype.calculateTTG = function (reqBody) {
        var riskLevel = this.getSavedUserDetails();
        reqBody.riskLevel = riskLevel && riskLevel.risk.riskLevel ? riskLevel.risk.riskLevel : 5;
        return this.http.post("goals/calculateTTG", reqBody);
    };
    DashboardService.prototype.getAllBrokerList = function () {
        return this.http.get("brokers/getAllBrokers");
    };
    DashboardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DashboardService_Factory() { return new DashboardService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i2.ActivatedRoute), i0.ɵɵinject(i3.CommonService)); }, token: DashboardService, providedIn: "root" });
    return DashboardService;
}());
export { DashboardService };
