import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(fb, commonService, router) {
        this.fb = fb;
        this.commonService = commonService;
        this.router = router;
        this.errMsg = "";
        this.apiCall = false;
    }
    LoginComponent.prototype.ngOnInit = function () {
        this.initForm();
    };
    LoginComponent.prototype.initForm = function () {
        this.loginForm = this.fb.group({
            email: ["", [Validators.required, Validators.email]],
            pin: ["", [Validators.required, Validators.maxLength(4), Validators.pattern("^[0-9]*$")]]
        });
    };
    Object.defineProperty(LoginComponent.prototype, "f", {
        get: function () { return this.loginForm.controls; },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.verifyUser = function () {
        var _this = this;
        this.errMsg = "";
        if (this.loginForm.valid) {
            this.apiCall = !this.apiCall;
            this.commonService.loginUser(this.loginForm.value).subscribe(function (res) {
                if (res && res.statusCode) {
                    _this.commonService.setToken(res.token);
                    UIkit.modal('#login-form').hide();
                    _this.router.navigate(['/dashboard']);
                    _this.apiCall = !_this.apiCall;
                }
                else {
                    _this.errMsg = res.msg;
                }
            });
        }
    };
    return LoginComponent;
}());
export { LoginComponent };
