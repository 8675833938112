import { OnInit } from '@angular/core';
import { CommonService } from '../shared/shared.module';
import { FormBuilder, Validators } from '@angular/forms';
import { environment } from "../../environments/environment";
// import {FormsModule,ReactiveFormsModule} from '@angular/forms';
var EmailVerifyComponent = /** @class */ (function () {
    function EmailVerifyComponent(route, commonService, fb) {
        this.route = route;
        this.commonService = commonService;
        this.fb = fb;
        this.queryParams = { email: "", deviceType: "", publicIp: "", isWeb: false };
        this.isMailSend = false;
        this.emailEdit = false;
        this.mailerAPICalled = 0;
        this.emailLimit = environment.emailLimit;
        this.getIp();
    }
    EmailVerifyComponent.prototype.ngOnInit = function () {
        this.getParams();
        this.initForm();
    };
    EmailVerifyComponent.prototype.initForm = function () {
        this.emailForm = this.fb.group({
            email: [this.queryParams.email, [Validators.required, Validators.email]]
        });
    };
    Object.defineProperty(EmailVerifyComponent.prototype, "f", {
        get: function () { return this.emailForm.controls; },
        enumerable: true,
        configurable: true
    });
    EmailVerifyComponent.prototype.getIp = function () {
        var _this = this;
        this.commonService.getClientIp().subscribe(function (res) {
            _this.clientIp = res.ip;
            _this.queryParams.publicIp = _this.clientIp;
        });
    };
    EmailVerifyComponent.prototype.getParams = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            //Since request params are in readonly state deep clone object
            _this.queryParams = JSON.parse(JSON.stringify(params));
            _this.queryParams.isWeb = _this.queryParams.isWeb ? _this.queryParams.isWeb : "";
            _this.queryParams.publicIp = _this.clientIp;
            _this.getReferralUrl();
            setTimeout(function () { return _this.sendEmail(); }, 3000);
        });
    };
    EmailVerifyComponent.prototype.getReferralUrl = function () {
        var referralUrl = JSON.parse(JSON.stringify(this.queryParams));
        delete referralUrl.email;
        delete referralUrl.deviceType;
        delete referralUrl.publicIp;
        delete referralUrl.isWeb;
        this.queryParams.referralUrl = referralUrl && Object.entries(referralUrl).length ? this.commonService.convertObjectToQuery(referralUrl) : "";
    };
    EmailVerifyComponent.prototype.sendEmail = function () {
        var _this = this;
        this.commonService.sendUserEmail(this.queryParams.email, this.queryParams.deviceType, this.queryParams.publicIp, this.queryParams.referralUrl).subscribe(function (res) {
            _this.isMailSend = true;
            setTimeout(function () { return _this.processEmailVerified(); }, 10000);
        });
    };
    EmailVerifyComponent.prototype.processEmailVerified = function () {
        var _this = this;
        this.mailSenderSub = setInterval(function () { return _this.isEmailVerified(); }, 5000);
    };
    EmailVerifyComponent.prototype.isEmailVerified = function () {
        var _this = this;
        if (this.mailerAPICalled < this.emailLimit) {
            this.mailerAPICalled++;
            this.commonService.isEmailVerified(this.queryParams.email).subscribe(function (data) {
                if (data.statusCode) {
                    location.href = "/verified?accessToken=" + data.validationCode + "&isWeb=" + _this.queryParams.isWeb;
                }
            });
        }
        else {
            this.clearTimer();
        }
    };
    EmailVerifyComponent.prototype.clearTimer = function () {
        clearInterval(this.mailSenderSub);
    };
    EmailVerifyComponent.prototype.resendEmail = function () {
        this.queryParams.email = this.emailForm.get('email').value;
        this.sendEmail();
        this.clearTimer();
        this.emailEdit = !this.emailEdit;
        this.isMailSend = !this.isMailSend;
        this.mailerAPICalled = 0;
    };
    EmailVerifyComponent.prototype.editEmail = function () {
        this.emailEdit = !this.emailEdit;
    };
    return EmailVerifyComponent;
}());
export { EmailVerifyComponent };
