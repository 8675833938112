import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
var subject = new Subject();
var BondsService = /** @class */ (function () {
    function BondsService(http, router, activatedRoute) {
        this.http = http;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.bondsSubject = subject;
    }
    BondsService.prototype.getBondsSubject = function () {
        return this.bondsSubject.asObservable();
    };
    BondsService.prototype.senBondsSubject = function (action, data) {
        this.bondsSubject.next({ action: action, data: data });
    };
    BondsService.prototype.setBondName = function (bondName) {
        return bondName.toLowerCase().replace(/^\w|\s\w/g, function (letter) {
            return letter.toUpperCase();
        });
    };
    BondsService.prototype.getMarketplace = function () {
        return this.http.get('bond/marketplace');
    };
    BondsService.prototype.getBondList = function (reqBody) {
        return this.http.post('bond/bondList', reqBody);
    };
    BondsService.prototype.getBondData = function (instrumentId) {
        return this.http.get("bond/bondData/" + instrumentId);
    };
    BondsService.prototype.getQuoteData = function (instrumentId, quoteType) {
        return this.http.get("bond/getQuoteData/" + instrumentId + "/" + quoteType);
    };
    BondsService.prototype.getUserInfo = function () {
        return this.http.get('bond/getUserInfo');
    };
    BondsService.prototype.updateDematAccount = function (reqBody) {
        return this.http.post('bond/updateDematAccount', reqBody);
    };
    BondsService.prototype.createHarmoneyUser = function () {
        return this.http.get('bond/createUser');
    };
    BondsService.prototype.placeOrder = function (reqBody) {
        return this.http.post('bond/placeOrder', reqBody);
    };
    BondsService.prototype.getPendingStatus = function (instrumentId) {
        return this.http.get("bond/getPendingStatus/" + instrumentId);
    };
    BondsService.prototype.getUserPortfolio = function () {
        return this.http.get('bond/getHoldings');
    };
    BondsService.prototype.isPaymentDone = function (paymentId) {
        return this.http.get("bond/user-orderStatus/" + paymentId);
    };
    BondsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BondsService_Factory() { return new BondsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i2.ActivatedRoute)); }, token: BondsService, providedIn: "root" });
    return BondsService;
}());
export { BondsService };
