import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import * as JsEncryptModule from 'jsencrypt';
import { environment } from '../../environments/environment';
var GetAccountInfoComponent = /** @class */ (function () {
    function GetAccountInfoComponent(fb, commonService, router) {
        this.fb = fb;
        this.commonService = commonService;
        this.router = router;
        this.isWeb = false;
        this.dropPenny = false;
        this.paymentBlock = false;
        this.pennyBankObj = { isPennyDrop: false, isBankVerified: false, isRejected: false, };
        this.apiCall = false;
        this.userAccountInfo = { accountNo: "", ifscCode: "", payType: "UPI", tpvBankId: "", phone: "", userAccountC: { accountVerifiedCount: 0 } };
        this.tpvBankError = false;
        this.tvpBankList = [];
        this.selectedItems = [];
        this.dropdownSettings = {};
        this.isSinglePaymentValid = false;
    }
    GetAccountInfoComponent.prototype.ngOnInit = function () {
        this.getQuery();
        this.setToken();
        this.getTVPBanks();
        this.initForm();
        this.getUserAccount();
        this.setDropdownSetting(false);
    };
    GetAccountInfoComponent.prototype.getQuery = function () {
        var _this = this;
        this.router.params.subscribe(function (params) {
            _this.userId = params.userId ? params.userId : _this.userId;
            _this.accessToken = params.accessToken ? params.accessToken : _this.accessToken;
            _this.coupounCode = params.coupounCode ? params.coupounCode : _this.coupounCode;
            //Check for whether its from web
            if (_this.accessToken == "-1") {
                _this.accessToken = _this.commonService.getToken();
                _this.isWeb = true;
            }
        });
    };
    GetAccountInfoComponent.prototype.getUserAccount = function () {
        var _this = this;
        this.commonService.getAccount(this.userId).subscribe(function (res) {
            if (res && res.statusCode) {
                _this.userAccountInfo = res.accountInfo;
                //Allow payment if user has tried for 2 times and both are invalid
                _this.paymentBlock = _this.userAccountInfo.userAccountC.accountVerifiedCount > 1 && !_this.userAccountInfo.accountNo ? true : false;
                _this.setDisable();
            }
        });
    };
    GetAccountInfoComponent.prototype.setToken = function () {
        var tokenObj = {
            id: this.accessToken,
            userId: this.userId,
        };
        this.commonService.setToken(tokenObj);
    };
    GetAccountInfoComponent.prototype.initForm = function () {
        this.bankForm = this.fb.group({
            accountNo: [this.userAccountInfo.accountNo, [Validators.required, Validators.pattern(/^\d{9,18}$/)]],
            ifscCode: [this.userAccountInfo.ifscCode, [Validators.required, Validators.pattern(/^[A-Za-z]{4}[a-zA-Z0-9]{7}$/)]],
            tpvBankId: [this.userAccountInfo.tpvBankId],
            payType: [this.userAccountInfo.payType, [Validators.required]],
        });
    };
    GetAccountInfoComponent.prototype.setDisable = function () {
        var _this = this;
        this.bankForm.patchValue({
            accountNo: this.userAccountInfo.accountNo,
            ifscCode: this.userAccountInfo.ifscCode,
            payType: this.userAccountInfo.payType,
            tpvBankId: this.userAccountInfo.tpvBankId,
        });
        this.userAccountInfo.accountNo && this.bankForm.get('accountNo').disable();
        this.userAccountInfo.ifscCode && this.bankForm.get('ifscCode').disable();
        if (this.userAccountInfo.tpvBankId) {
            this.selectedItems = this.tvpBankList.filter(function (b) { return b.id == _this.userAccountInfo.tpvBankId; });
            this.bankForm.get('tpvBankId').disable();
            this.setDropdownSetting(true);
        }
        // if (this.isSinglePaymentValid) {
        //   // this.bankForm.get("payType").setValue("NETBANKING")
        //   this.bankForm.get("payType").setValue("UPI")
        // }
    };
    GetAccountInfoComponent.prototype.setDropdownSetting = function (disabled) {
        this.dropdownSettings = {
            singleSelection: true,
            text: "Select Bank",
            enableSearchFilter: true,
            enableCheckAll: false,
            searchAutofocus: true,
            noDataLabel: "Bank not present select other",
            selectAllText: "",
            unSelectAllText: "",
            disabled: disabled,
            maxHeight: "150"
        };
    };
    GetAccountInfoComponent.prototype.getTVPBanks = function () {
        var _this = this;
        this.commonService.getTVPBank().subscribe(function (res) {
            if (res && res.statusCode) {
                var tvpBanks = res.tvpBanks;
                var tempBanks_1 = [];
                tvpBanks.forEach(function (t) {
                    tempBanks_1.push({ id: t.id, itemName: t.bankName });
                });
                //Make Other as last options
                var otherBank = tempBanks_1.shift();
                tempBanks_1.push(otherBank);
                _this.tvpBankList = tempBanks_1;
            }
        });
    };
    Object.defineProperty(GetAccountInfoComponent.prototype, "f", {
        get: function () { return this.bankForm.controls; },
        enumerable: true,
        configurable: true
    });
    GetAccountInfoComponent.prototype.pennyDrop = function () {
        if (this.bankForm.valid) {
            if (this.bankForm.get("accountNo").disabled && this.bankForm.get("ifscCode").disabled)
                this.callPenny();
            else
                this.toggleConfirmAccountNo(true);
        }
    };
    GetAccountInfoComponent.prototype.toggleConfirmAccountNo = function (show) {
        show && UIkit.modal('#confirmAccount', { 'bg-close': false }).show();
        !show && UIkit.modal('#confirmAccount', { 'bg-close': false }).hide();
    };
    GetAccountInfoComponent.prototype.callPenny = function () {
        var _this = this;
        if (this.bankForm.valid) {
            this.toggleConfirmAccountNo(false);
            var reqBody = JSON.stringify(this.bankForm.getRawValue());
            reqBody = this.encrypt(reqBody);
            this.dropPenny = true;
            this.apiCall = true;
            this.commonService.pennyDrop({ reqBody: reqBody }).subscribe(function (res) {
                _this.apiCall = false;
                if (res && res.statusCode) {
                    _this.pennyOrderId = res.pennyOrderId;
                    _this.pennyBankObj.isPennyDrop = _this.pennyOrderId ? true : false;
                    //If penny drop is successfull then start to verify from service
                    _this.pennyBankObj.isPennyDrop && _this.processPennyVerified();
                }
                //Check for error message
                _this.pennyErrorMsg = res && !res.statusCode && res.msg ? res.msg : '';
                //Whther its rejected
                _this.pennyBankObj.isRejected = _this.pennyErrorMsg && res.isRejected ? true : false;
                //If rejected make penny to account as suucess;
                _this.pennyBankObj.isPennyDrop = _this.pennyBankObj.isRejected ? true : _this.pennyBankObj.isPennyDrop;
            });
        }
    };
    GetAccountInfoComponent.prototype.encrypt = function (reqBody) {
        var bufferValue1 = "";
        var encrypt = new JsEncryptModule.JSEncrypt();
        encrypt.setPublicKey("-----BEGIN PUBLIC KEY-----\n    MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAIlbArvv/i+qJ+vjk1LMGDrR/v+Qa9sN\n    OJMWDt2txAS3TkVUHOtbpSCFEEfS/yj2sBvHLIPnrmSGgAZWmRTCEtcCAwEAAQ==\n    -----END PUBLIC KEY-----");
        var splitData1 = reqBody.match(/.{1,30}/g);
        splitData1.forEach(function (s) {
            bufferValue1 += encrypt.encrypt(s);
        });
        return bufferValue1;
    };
    GetAccountInfoComponent.prototype.processPennyVerified = function () {
        var _this = this;
        this.verifyBankObj = setInterval(function () { return _this.isPennyDrop(); }, 5000);
        // this.verifyBankObj = setInterval(() => this.isPennyDrop(), 10000);
        // this.verifyBankObj = setInterval(() => this.isPennyDrop(), 120000);
    };
    GetAccountInfoComponent.prototype.isPennyDrop = function () {
        var _this = this;
        this.commonService.isPennyDrop(this.pennyOrderId).subscribe(function (data) {
            if (data && data.statusCode) {
                _this.pennyBankObj.isBankVerified = data.statusCode && data.validBank ? true : false;
                // location.href = `/verified?accessToken=${data.validationCode}`;
            }
            else {
                _this.pennyErrorMsg = data && !data.statusCode && data.msg ? data.msg : '';
                _this.pennyBankObj.isRejected = _this.pennyErrorMsg && data.isRejected ? true : false;
                _this.clearTimer();
            }
            !_this.pennyErrorMsg && _this.pennyBankObj.isBankVerified && _this.clearTimer();
        });
    };
    GetAccountInfoComponent.prototype.clearTimer = function () {
        clearInterval(this.verifyBankObj);
    };
    GetAccountInfoComponent.prototype.editAccNo = function () {
        this.dropPenny = false;
        this.pennyBankObj.isBankVerified = false;
        this.pennyBankObj.isPennyDrop = false;
        this.pennyBankObj.isRejected = false;
        this.pennyErrorMsg = "";
        this.selectedItems = [];
        this.bankForm.reset();
        this.getUserAccount();
    };
    GetAccountInfoComponent.prototype.redirectToPayment = function () {
        //@ts-ignore
        window.location = environment.url + "/processingPayments/makePayment/" + this.userId + "/" + this.coupounCode;
    };
    GetAccountInfoComponent.prototype.onBankSelect = function (bank) {
        if (bank.id != "1") {
            this.bankForm.get('tpvBankId').setValue(bank.id);
            // console.log(this.bankForm.getRawValue());
        }
        else {
            this.editBank();
            this.bankForm.get('tpvBankId').setValue("");
            this.selectedItems = [];
        }
    };
    GetAccountInfoComponent.prototype.OnBankDeSelect = function (bank) {
        this.bankForm.get('tpvBankId').setValue("");
    };
    GetAccountInfoComponent.prototype.editBank = function () {
        this.tpvBankError = !this.tpvBankError;
    };
    GetAccountInfoComponent.prototype.cancelPayment = function () {
        location.href = "/refute?statusCode=0&msg=Payment cancelled&title=Payment Incomplete&shouldRetry=1";
    };
    GetAccountInfoComponent.prototype.openDropDown = function () {
        $('.tagToBody').css('top', '').css('left', '');
    };
    return GetAccountInfoComponent;
}());
export { GetAccountInfoComponent };
