import * as moment from "moment";
import { environment } from '../../../../environments/environment';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
var subject = new Subject();
var CommonService = /** @class */ (function () {
    function CommonService(http, router, activatedRoute) {
        this.http = http;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.commonSubject = subject;
        this.currentFinancialYear();
    }
    CommonService.prototype.getCommonSubject = function () {
        return this.commonSubject.asObservable();
    };
    CommonService.prototype.sendCommonSubject = function (action, data) {
        this.commonSubject.next({ action: action, data: data });
    };
    CommonService.prototype.sendUserEmail = function (email, deviceType, publicIp, referralUrl) {
        return this.http.get("users/sendUserEmail/" + email + "/" + deviceType + "/" + publicIp + "?" + referralUrl);
    };
    CommonService.prototype.isEmailVerified = function (email) {
        return this.http.get("users/IsEmailVerify/" + email);
    };
    CommonService.prototype.getClientIp = function () {
        return this.http.get(environment.ipAddress);
    };
    CommonService.prototype.getToken = function () {
        return localStorage.getItem('aesToken');
    };
    CommonService.prototype.getDataFromLocalStorage = function (keyName) {
        var keyData = localStorage.getItem(keyName);
        return keyData;
    };
    CommonService.prototype.getDataFromSessionStorage = function (keyName) {
        var keyData = sessionStorage.getItem(keyName);
        return keyData;
    };
    CommonService.prototype.setLocalStorage = function (keyName, data) {
        localStorage.setItem(keyName, data);
    };
    CommonService.prototype.setSessionStorage = function (keyName, data) {
        sessionStorage.setItem(keyName, data);
    };
    CommonService.prototype.setToken = function (token) {
        localStorage.setItem('aesToken', token.id);
        this.setUserInfo({ id: token.userId });
        this.setTTL();
    };
    CommonService.prototype.setUserInfo = function (userInfo) {
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
    };
    CommonService.prototype.setTTL = function () {
        localStorage.setItem('aesTTL', new Date().valueOf().toString());
    };
    CommonService.prototype.removeToken = function () {
        localStorage.clear();
    };
    CommonService.prototype.getUserId = function () {
        var userInfo = localStorage.getItem('userInfo');
        userInfo = userInfo && JSON.parse(userInfo);
        var userId = userInfo ? userInfo.id : "";
        return userId;
    };
    CommonService.prototype.isAuthenticated = function () {
        var status = false;
        if (this.getToken()) {
            var aesTTL = localStorage.getItem('aesTTL');
            var duration = moment.duration(moment().diff(moment(Number(aesTTL))));
            status = duration.asMinutes() > environment.expiryInMin ? false : true;
        }
        return status;
    };
    CommonService.prototype.loginUser = function (postBody) {
        return this.http.post("users/webAppLogin", postBody);
    };
    // primary	UIkit.notification("...", { status:'primary'})
    // success	UIkit.notification("...", { status:'success'})
    // warning	UIkit.notification("...", { status:'warning'})
    // danger	UIkit.notification("...", { status:'danger'})
    CommonService.prototype.showNotification = function (message, status, pos) {
        if (pos === void 0) { pos = 'bottom-left'; }
        UIkit.notification(message, { status: status, pos: pos });
    };
    CommonService.prototype.getETFPrices = function () {
        return this.http.get("currentEtfs/getEtfDetails");
    };
    CommonService.prototype.currentFinancialYear = function () {
        var financialYears = [];
        var currentDate = moment();
        var years = -1;
        var currentFY = moment().month(3).startOf('month');
        if (currentDate <= currentFY) {
            for (var i = 0; i < 3; i++) {
                var cT = {
                    start: moment().month(3).startOf('month').add(years, 'year'),
                    end: moment().month(2).endOf('month').add('years', 1).add(years, 'year')
                };
                financialYears.push(cT);
                years--;
            }
        }
        else {
            years = 0;
            for (var i = 0; i < 3; i++) {
                var cT = {
                    start: moment().month(3).startOf('month').add(years, 'year'),
                    end: moment().month(2).endOf('month').add('years', 1).add(years, 'year')
                };
                financialYears.push(cT);
                years--;
            }
        }
        localStorage.setItem('financialYear', JSON.stringify(financialYears));
    };
    CommonService.prototype.getETFs = function () {
        var etfs = localStorage.getItem('etfs');
        etfs = etfs ? JSON.parse(etfs) : [];
        return etfs;
    };
    CommonService.prototype.setETFs = function (etfs) {
        localStorage.setItem('etfs', JSON.stringify(etfs));
    };
    CommonService.prototype.pennyDrop = function (reqBody) {
        return this.http.post("pennyDropAccounts/pennyDropService", reqBody);
    };
    CommonService.prototype.isPennyDrop = function (orderId) {
        return this.http.get("pennyDropAccounts/isPennyDrop/" + orderId);
    };
    CommonService.prototype.getAccount = function (userId) {
        return this.http.get("pennyDropAccounts/getAccount/");
    };
    CommonService.prototype.getTVPBank = function () {
        return this.http.get("tpvBanks/getTVPBank");
    };
    CommonService.prototype.getOrderId = function (userId, cc, paymentFrequency) {
        return this.http.get("processingPayments/getOrderId/" + userId + "/" + cc + "/" + paymentFrequency);
    };
    CommonService.prototype.checkRazorPayStatus = function (orderId, paymentId, checksum) {
        return this.http.get("processingPayments/checkRazorPayStatus/" + orderId + "/" + paymentId + "/" + checksum);
    };
    CommonService.prototype.getDateDifference = function (startDate, endDate, returnAs, dateFormate) {
        if (dateFormate === void 0) { dateFormate = null; }
        var start = dateFormate ? moment(startDate, dateFormate) : moment(startDate);
        var end = endDate ? moment(endDate) : moment();
        var diff = 0;
        diff = end.diff(start, returnAs);
        return diff;
    };
    CommonService.prototype.getUserInfo = function () {
        return this.http.get("users/currentUser");
    };
    CommonService.prototype.authenticateGoogleToken = function (reqBody, referralUrl) {
        return this.http.post("/users/googleLogin?" + referralUrl, reqBody);
    };
    CommonService.prototype.getAccessTokenFromEmail = function (emailToken) {
        return this.http.get("/users/getAccessToken/" + emailToken);
    };
    CommonService.prototype.convertMoneyToFraction = function (amount) {
        amount = isNaN(amount) ? amount : "" + amount;
        return parseFloat(amount.replace(/,/g, ''));
    };
    CommonService.prototype.subscribeUser = function (reqBody) {
        return this.http.post("/subscribers", reqBody);
    };
    CommonService.prototype.sendRiskMail = function (reqBody) {
        return this.http.post("/subscribers/mailRiskAnalysis", reqBody);
    };
    CommonService.prototype.getAllPaymentfrequency = function () {
        return this.http.get("/admin/getAllFrequency");
    };
    CommonService.prototype.checkCoupoun = function (coupounCode) {
        return this.http.get("/processingPayments/checkCoupoun/" + coupounCode);
    };
    CommonService.prototype.isWebRequest = function () {
        var isWeb = this.getDataFromLocalStorage('isWeb');
        return isWeb;
    };
    CommonService.prototype.convertObjectToQuery = function (referralUrl) {
        return Object.entries(referralUrl).map(function (e) { return e.join('='); }).join('&');
    };
    CommonService.prototype.brokerImages = function (brokerName) {
        var brokerImage = '';
        switch (brokerName) {
            case 'ALICEBLUE':
                brokerImage = 'https://tavaga-assets.s3.ap-south-1.amazonaws.com/brokers/aliceblue.png';
                break;
            case 'FIVEPAISA':
                brokerImage = 'https://tavaga-assets.s3.ap-south-1.amazonaws.com/brokers/5paisa.png';
                break;
            case 'KOTAK':
                brokerImage = 'https://tavaga-assets.s3.ap-south-1.amazonaws.com/brokers/kotak.png';
                break;
            case 'DHAN':
                brokerImage = 'https://tavaga-assets.s3.ap-south-1.amazonaws.com/brokers/dhan_tparent_long.png';
                break;
            default:
                break;
        }
        return brokerImage;
    };
    CommonService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CommonService_Factory() { return new CommonService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i2.ActivatedRoute)); }, token: CommonService, providedIn: "root" });
    return CommonService;
}());
export { CommonService };
